<template>
  <div class="page-container billing-transfer-main-page">
    <portal to="page-name">Repasses</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Repasse dos profissionais</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-6 form-group"
               :class="{'has-error': $v.filter.startDate.$error
                || $v.filter.endDate.$error}">
            <label class="form-label">Período</label>
            <div class="input-group">
              <dx-dropdown
                class="input-group-addon"
                :items="periodOptions"
                label="text"
                @select="setPeriod"
                direction="left"
              ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
              <select class="form-select" style="max-width: 150px"
                      v-model="filter.dateType">
                <option value="account">Data conta</option>
                <option value="expense">Data procedimento</option>
              </select>
              <dx-input-date
                id="start-date"
                class="form-input text-center"
                v-model="filter.startDate"
                @change="changedStartDate"
                @blur="$v.filter.startDate.$touch()"
              />
              <dx-input-date
                id="end-date"
                class="form-input text-center"
                v-model="filter.endDate"
                @change="changedEndDate"
                @blur="$v.filter.endDate.$touch()"
              />
            </div>
          </div>
          <div class="column col-6 form-group">
            <label class="form-label">Status da fatura</label>
            <div class="btn-group btn-group-block">
              <button class="btn btn-icon-left"
                      :class="hasStatusOpen ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('open')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusOpen" />Aberto
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusInBilling ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('in_billing')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusInBilling" />Faturando
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusClosed ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('closed')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusClosed" />Fechada
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusInAppeal ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('in_appeal')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusInAppeal" />Recurso
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusFinished ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('finished')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusFinished" />Finalizada
              </button>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column col-6 col-md-12 col-sm-12 form-group">
            <label class="form-label">Profissional</label>
            <select class="form-select"
                    v-model="filter.professionalId">
              <option value="">[Todos os profissionais]</option>
              <option v-for="(professional, i) in professionals"
                      :value="professional.id" :key="i">{{ professional.name }}</option>
            </select>
          </div>
          <div class="column col-md-12 col-sm-12 form-group">
            <label class="form-label">Convênio</label>
            <select class="form-select"
                    v-model="filter.insurancePlanId">
              <option value="">[Todos os convênios]</option>
              <option v-for="(insurance, i) in insurancePlans"
                      :value="insurance.id" :key="i">{{ insurance.name }}</option>
            </select>
          </div>
          <div class="column col-auto form-group"
               style="align-self: flex-end">
            <button class="btn btn-primary btn-icon-left mb-2"
                    :class="{loading}"
                    :disabled="loading"
                    @click="load">
              <fa-icon class="icon" :icon="['fal', 'search']"></fa-icon>
              Atualizar
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <div class="divider" />
          <div class="h6">Profissionais</div>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Repasse</p>
              <p class="empty-subtitle">
                Nenhuma conta encontrada. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <label class="form-switch">
              <input type="checkbox" v-model="filter.allValues">
              <i class="form-icon"></i>Exibir repasses zerados
            </label>
            <div v-for="(professional, i) in data.items" :key="i">
              <professional-transfer-item
                :data="professional"
                :filter="filter"
                @update="load"
              />
            </div>
          </template>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <span>Quantidade de profissionais:
          <strong class="ml-2 text-primary">{{ data.total }}</strong>
        </span>
        <span class="float-right mr-1">Total:
          <strong class="text-primary">{{ total | currency }}</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import * as professionalApi from '@/app/professional/api';
import ProfessionalTransferItem from './TransferItem.vue';
import { date, minDate } from '../../../../data/validators';

export default {
  components: {
    ProfessionalTransferItem,
  },
  data() {
    return {
      debounceTimeout: null,
      insurancePlans: [],
      professionals: [],
      loading: false,
      periodOptions: [],
      filter: {
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
        insurancePlanId: '',
        professionalId: '',
        dateType: 'account',
        statuses: ['finished'],
        allValues: true,
      },
      data: {
        items: [],
        total: 3,
        limit: 100,
        offset: 0,
      },
    };
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.loadPeriod();
    this.load();
  },
  computed: {
    total() {
      return this.data.items.reduce((result, item) => {
        result += item.accounts.reduce((a, b) => a + Number(b.total), 0);
        return result;
      }, 0);
    },
    hasStatusOpen() {
      return this.filter.statuses.find(item => item === 'open');
    },
    hasStatusInBilling() {
      return this.filter.statuses.find(item => item === 'in_billing');
    },
    hasStatusClosed() {
      return this.filter.statuses.find(item => item === 'closed');
    },
    hasStatusInAppeal() {
      return this.filter.statuses.find(item => item === 'in_appeal');
    },
    hasStatusFinished() {
      return this.filter.statuses.find(item => item === 'finished');
    },
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  methods: {
    load() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return null;
      }

      const duration = moment.duration(moment(this.filter.endDate)
        .diff(moment(this.filter.startDate))).asMonths();

      if (duration > 12) {
        this.$toast.show('O intervalo entre as datas não pode ser superior a 12 meses!',
          { type: 'error' });
        return false;
      }

      this.loading = true;

      const params = {
        dateType: this.filter.dateType,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        statuses: this.filter.statuses.join(','),
      };

      if (this.filter.insurancePlanId) {
        params.insurancePlanId = this.filter.insurancePlanId;
      }

      if (this.filter.professionalId) {
        params.professionalId = this.filter.professionalId;
      }

      return this.$httpX.get('/professional-transfer', { params })
        .then(({ data }) => {
          data.items.forEach(({ accounts }) => {
            accounts.forEach((item) => {
              item.selected = true;
              return item;
            });
          });
          this.data = data;
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurancePlans = data.items.map(item => ({
            id: item.plan.id,
            type: item.type,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    loadPeriod() {
      const now = new Date();
      for (let i = 0; i < 6; i += 1) {
        this.periodOptions.push(
          {
            value: i,
            text: moment(now).subtract(i, 'month').format('MM/YYYY'),
          },
        );
      }
    },
    changedStartDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
    },
    changedEndDate() {
      if (!this.filter.startDate && !this.filter.endDate) {
        this.filter.startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = moment(this.filter.startDate)
          .endOf('month')
          .format('YYYY-MM-DD');
      }
    },
    setPeriod({ value }) {
      this.filter.startDate = moment()
        .subtract(value, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.endDate = moment()
        .subtract(value, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    setStatus(status) {
      const found = this.filter.statuses.find(item => item === status);
      if (found) {
        if (this.filter.statuses.length > 1) {
          this.filter.statuses.splice(this.filter.statuses.indexOf(found), 1);
        }
      } else {
        this.filter.statuses.push(status);
      }
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import 'src/assets/scss/colors';

  .billing-transfer-main-page {
  }
</style>
